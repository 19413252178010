.SuccessCustom {
  .Toastify__progress-bar {
    background-color: var(--primary) !important;
  }
  .Toastify__toast-icon {
    svg {
      fill: var(--primary) !important;
    }
  }
}

.ErrorCustom {
  .Toastify__progress-bar {
    background-color: #e74c3c !important;
  }
  .Toastify__toast-icon {
    svg {
      fill: #e74c3c !important;
    }
  }
}
