@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800;900&display=swap");

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  .about_us li {
    list-style: disc;
    margin-left: 24px;
  }
  .about_us ul {
    margin-top: 10px;
    margin-bottom: 8px;
  }
  /* Icon ChatFlows */
  #hubspot-messages-iframe-container {
    transform: translate(-8px, -85px);
  }

  /* Animation dot */
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #3e80ff;
    color: #3e80ff;
    box-shadow: 9984px 0 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    animation: dotTyping 1.5s infinite linear;
  }

  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    }
    50% {
      box-shadow: 9984px 0 0 0 #3e80ff, 9999px -10px 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px -10px 0 0 #3e80ff;
    }
    100% {
      box-shadow: 9984px 0 0 0 #3e80ff, 9999px 0 0 0 #3e80ff, 10014px 0 0 0 #3e80ff;
    }
  }
}
@layer base {
  .flex-center {
    @apply flex items-center justify-center;
  }
}
body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
