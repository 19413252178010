:root {
  --primary: #3e80ff;
  --secondary: #ffffff;
  --overlay: rgba(0, 0, 0, 0.8);
  --bg-dark: #101828;
  --bg-gray: #1d2939;
  --bg-gray-primary: #272f3d;
  --text-color-primary: #ffffff;
  --text-color-secondary: rgba(255, 255, 255, 0.87);
  --text-placeholder: rgba(255, 255, 255, 0.38);
  --text-title: rgba(255, 255, 255, 0.87);
  --text-label: rgba(255, 255, 255, 0.6);
  --border-color: rgba(255, 255, 255, 0.1);
  --text-size: 18px;
  --border-input: #667085;
  --border-style: 1px solid rgba(255, 255, 255, 0.1);
  --border-radius-default: 10px;
  --bg-primary-hover: #1C69FF;
}
